*,
*:before,
*:after {
  min-width: 0;
  min-height: 0;
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: var(--white);
}

strong,
b {
  font-weight: bold;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
}

:root {
  --skullWhite: #ffffff;
}
